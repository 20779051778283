import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import startsWith from 'lodash/startsWith';
import filter from 'lodash/filter';
import find from 'lodash/find';
import map from 'lodash/map';
import moment from 'moment';
import { parseDate } from 'utils/time';

const DAYS_IN_MS = 1000 * 60 * 60 * 24;
const HOURS_IN_MS = 1000 * 60 * 60;
const MIN_IN_MS = 1000 * 60;
const SEC_IN_MS = 1000;

const regExpFromString = (q) => {
  let flags = q.replace(/.*\/([gimuy]*)$/, '$1');
  if (flags === q) flags = '';
  let pattern = flags
    ? q.replace(new RegExp('^/(.*?)/' + flags + '$'), '$1')
    : q;
  try {
    return new RegExp(pattern, flags);
  } catch (e) {
    return null;
  }
};

export const getDisplayDateTime = (dateTimeString) => {
  const dateTimeObj = moment(dateTimeString);
  let displayDateTime = '';
  if (dateTimeObj.isValid()) {
    displayDateTime = dateTimeObj
      .tz(moment.tz.guess())
      .format('DD/MM/YYYY [at] hh:mma z');
  }
  return displayDateTime;
};

export const validateField = (block, props, value) => {
  const {
    id = '',
    label = '',
    validations: { regexList = [] } = { regexList: [] },
  } = block;
  const { onFieldValidationCallback } = props;
  let validationMessage = null;
  if (!isEmpty(regexList) && value) {
    regexList.every((v) => {
      if (v === 'MIN_AGE_18') {
        const dateOfBirth = parseDate(value);
        if (
          typeof dateOfBirth === 'undefined' ||
          moment().diff(dateOfBirth, 'years') < 18
        ) {
          validationMessage = 'User must be 18 or older';
          return false;
        }
      } else if (v === 'MAX_LENGTH_40') {
        if (value?.length > 40) {
          validationMessage = `Invalid ${label} provided.`;
          return false;
        }
      } else if (v === 'MAX_25_WORDS') {
        let words = value.split(/\s+/);
        if (words.length > 25) {
          validationMessage = 'Please summarise in 25 words or less';
          return false;
        }
      } else {
        let re = regExpFromString(v);
        if (!re.test(value)) {
          validationMessage = `Invalid ${label} provided.`;
          return false;
        }
      }
      return true;
    });
  }

  if (block?.required) {
    if (!value && !validationMessage) {
      validationMessage = `Field is required.`;
    }
  }

  onFieldValidationCallback(id, !validationMessage);
  return validationMessage;
};

export const getAllFormFields = (template) => {
  const formFields = [];

  template.elements.forEach(function iter(o) {
    o.isFormField && formFields.push(o);
    (o.elements || []).forEach(iter);
  });

  return formFields.map((v) => {
    return {
      id: v.id,
      value: v.value || v.defaultValue,
      isValid: v.isValid || !v.required || false,
      sendToApi: v.submitValue || false,
    };
  });
};

export const fillFormFieldsWithApiValues = (formFields, data) => {
  if (!isEmpty(data)) {
    return formFields.map((v) => {
      return {
        ...v,
        value: data[v.id] || v.value,
      };
    });
  }
  return formFields;
};

const formatNumber = (num) => num.toString().padStart(2, '0');

export const calculateTimeLeft = (
  isCompetitionStarted,
  startDateMillis,
  endDateMillis,
) => {
  const currentTimeMillis = Date.now();
  const time = isCompetitionStarted
    ? endDateMillis - currentTimeMillis
    : startDateMillis - currentTimeMillis;

  if (isCompetitionStarted && time < 0) {
    const zeroTime = '00';
    return {
      Days: zeroTime,
      Hours: zeroTime,
      Minutes: zeroTime,
      Seconds: zeroTime,
    };
  }

  return {
    Days: formatNumber(Math.floor(time / DAYS_IN_MS)),
    Hours: formatNumber(Math.floor((time / HOURS_IN_MS) % 24)),
    Minutes: formatNumber(Math.floor((time / MIN_IN_MS) % 60)),
    Seconds: formatNumber(Math.floor((time / SEC_IN_MS) % 60)),
  };
};

export const groupAllQuestionAnswers = (allFields) => {
  try {
    const allQuestionNumbers = map(
      filter(allFields, (f) => startsWith(f.id, 'question-')),
      (f) => f.id.split('-')[1],
    );

    const finalList = [];
    forEach(allQuestionNumbers, (number) => {
      const question = find(allFields, {
        id: `question-${number}`,
      });
      const answer = find(allFields, { id: `answer-${number}` });
      finalList.push({
        question: question.value,
        answer: answer.value || '',
      });
    });

    return finalList;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const getFavouriteTeam = (fieldsToSubmit) => {
  const favouriteTeamData = get(
    find(fieldsToSubmit, (f) => f.id === 'favouriteTeam'),
    ['value'],
    {},
  );

  const { id, TeamID, officialName } = favouriteTeamData;
  const favouriteTeam = {
    teamId: TeamID,
    teamName: officialName,
  };

  return {
    teamInternalIds: [
      {
        id,
      },
    ],
    favouriteTeam,
  };
};

export const isCompetitionStarted = (competitionObj) => {
  if (!isEmpty(competitionObj)) {
    const { startDate } = competitionObj;
    const compStartTimeMs = new Date(startDate).getTime();
    const currentTimeMs = new Date().getTime();
    return compStartTimeMs < currentTimeMs;
  }
};

export const stripHtmlTags = (str, allow) => {
  // making sure the allow arg is a string containing only tags in lowercase (<a><b><c>)
  allow = (
    ((allow || '') + '').toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []
  ).join('');

  var tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;
  var commentsTags = /<!--[\s\S]*?-->|<\?[\s\S]*?\?>/gi;
  return str.replace(commentsTags, '').replace(tags, function($0, $1) {
    return allow.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '';
  });
};

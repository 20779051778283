import _ from 'lodash';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import defaultConfig from '../../../constants/defaultConfig';
import isTouch from '../../../utils/mobileDetect';
import LoadingIcon from '../../ui/ui-kit/Icons/LoadingIcon';
import { editorialTitleMapping } from 'middleware/editorialTitleMapping';
import styles from './styles';
// import shortid from 'shortid';
import ConditionalWrapper from 'components/ui/ConditionalWrapper';
import LazyLoad from '../../ui/LazyLoad';
import { isPremiumUser } from 'selectors/user';
import { selectors as storeSelector } from '../../../store/createPortalStore';
import { assetFilter } from '../../../components/ui/PromotionalPlacements/utils';

const AssetsPanelUI = loadable(() => import('../../ui/AssetsPanel'));

class AssetsPanel extends Component {
  state = {
    carouselPosition: 'self-start',
    index: 0, // index of first card that showing on the page
    offset: 0, // px to transform
  };

  /**
   *
   * @param currentIndex
   * @param carouselLength
   * @param index
   * @param offset
   */
  setCarouselPosition(currentIndex, carouselLength, index, offset) {
    let carouselPosition = 'self-end';
    if (currentIndex === 0) {
      carouselPosition = 'self-start';
    } else if (currentIndex > 0 && currentIndex < carouselLength - 4) {
      carouselPosition = 'self-center';
    }
    this.props.newHome &&
      this.setState({
        carouselPosition,
        index,
        offset,
      });
  }

  render() {
    const {
      newHome,
      articleCarousel,
      classes,
      editorial,
      title,
      section,
      showViewAllLink,
      theme = 'dark',
      target,
      fitnessLanding,
      page,
      carouselId,
      carouselIndex,
      type,
      isPremiumUser,
      userTags,
      contentCardsInObj: {
        isReady: isContentCardsReady,
        data: contentCardsInObj,
      },
    } = this.props;

    let assetsPanel = null;
    let categoryPath;

    if (this.props.sectionPath) {
      categoryPath = `/${this.props.sectionPath}/category/${this.props.carouselId}`;
    } else {
      categoryPath = `/category/${this.props.carouselId}`;
    }

    if (
      (editorial && editorial.inProgress && !editorial.isSuccess) ||
      !userTags ||
      !isContentCardsReady
    ) {
      return (
        <div
          className={`flex flex-no-wrap flex-col w-full my-64 ${this.props.classes}`}
        >
          <div className='w-full pt-16 pb-8 pl-0 xl:pl-116 xl:pb-16'>
            <span className='text-left font-MarkProHeavy leading-double xl:leading-none text-xl xl:text-2xl min-w-220'>
              {editorialTitleMapping(this.props.editorial)}
            </span>
          </div>
          <LoadingIcon className='h-32 w-32 xl:ml-116' />
        </div>
      );
    }

    const assets = assetFilter(
      _.get(editorial, 'assets', []),
      userTags,
      contentCardsInObj,
    );
    if (assets && assets.length > 0) {
      assetsPanel = (
        <ConditionalWrapper
          condition={carouselIndex !== 0}
          wrapperIf={(children) => (
            <LazyLoad
              className={`${
                newHome ? 'new-home flex flex-col justify-center' : ''
              } ${type !== 'explore' ? 'min-h-224' : ''} layout-c-card-list`}
            >
              {children}
            </LazyLoad>
          )}
          wrapperElse={(children) => (
            <div
              className={`${
                newHome ? 'new-home flex flex-col justify-center' : ''
              } ${type !== 'explore' ? 'min-h-224' : ''} layout-c-card-list`}
            >
              {children}
            </div>
          )}
        >
          <AssetsPanelUI
            id={(editorial && editorial.id) || title}
            key={(editorial && editorial.id) || title}
            title={title}
            editorialId={editorial && editorial.id}
            imageBaseUrl={this.props.imageBaseUrl}
            className={this.props.className}
            panelCoverSize={this.props.panelCoverSize}
            categoryMapping={this.props.categoryMapping}
            assets={assets}
            isPremiumUser={isPremiumUser}
            isTouch={isTouch()}
            categoryPath={categoryPath}
            classes={classes}
            newHome={newHome}
            section={newHome && section}
            index={this.state.index}
            offset={this.state.offset}
            articleCarousel={articleCarousel}
            carouselPosition={
              newHome && section ? 'self-center' : this.state.carouselPosition
            }
            setCarouselPosition={this.setCarouselPosition.bind(this)}
            theme={theme}
            target={target}
            fitnessLanding={fitnessLanding}
            page={page}
            carouselId={carouselId}
            type={type}
          />
        </ConditionalWrapper>
      );
    }

    return (
      <>
        {newHome && assets.length > 0 && (
          <div
            className={`w-full pl-0 ${
              !newHome
                ? 'pt-16 pb-8 xl:pl-116 xl:pb-16'
                : 'pt-24 pb-8 md:py-24 lg:py-32'
            } flex justify-between items-center`}
          >
            <h2 className='text-left font-MarkProHeavy font-normal leading-double xl:leading-none text-xl xl:text-2xl '>
              {title}
            </h2>
            {showViewAllLink && (
              <Link
                to={
                  this.props.viewAllUrl ? this.props.viewAllUrl : categoryPath
                }
                className={`font-MarkPro ${styles[theme]?.viewAll} text-sm
                          ${newHome ? '' : 'pr-8 xl:pr-116 '}
                          z-20 hover:${styles[theme]?.viewAllHover} active:${
                  styles[theme]?.viewAllActive
                } os-transition-btn`}
              >
                View All
              </Link>
            )}
          </div>
        )}
        {assetsPanel}
      </>
    );
  }
}

AssetsPanel.propTypes = {
  carouselId: PropTypes.string.isRequired,
  sectionPath: PropTypes.string,
  title: PropTypes.string,
  classes: PropTypes.string,
  newHome: PropTypes.bool,
  section: PropTypes.bool,
  articleCarousel: PropTypes.bool,
  theme: PropTypes.string,
  target: PropTypes.string,
  fitnessLanding: PropTypes.bool,
  viewAllUrl: PropTypes.string,
  page: PropTypes.string,
};

AssetsPanel.defaultProps = {
  sectionPath: null,
  classes: '',
  newHome: false,
  section: false,
  articleCarousel: false,
  showViewAllLink: true,
  page: null,
  carouselIndex: null,
};

function mapStateToProps(state, ownProps) {
  const editorials = state.editorials.filter(
    (editorial) => editorial.id === ownProps.carouselId,
  );
  let editorial = _.get(editorials, '[0]', null);
  const imageBaseUrl =
    _.get(state, 'systemConfig.image.baseUrl', null) ||
    defaultConfig.image.baseUrl;
  const panelCoverSize =
    _.get(state, 'systemConfig.image.sizes.carouselRailSize', null) ||
    defaultConfig.image.sizes.carouselRailSize;
  const categoryMapping = _.get(state, 'systemConfig.categoryMapping', null);
  const isUserPremium = isPremiumUser(state);

  // article rail carousel on legacy home
  if (ownProps.articleCarousel) {
    editorial = {};
    editorial.assets = _.get(ownProps, 'articlesList', null);
    editorial.title = _.get(ownProps, 'title', '');
  }
  const userTags = storeSelector.user.getUserTags(state);
  const contentCardsInObj = storeSelector.contentCards.getContentCardsInObj(
    state,
  );
  return {
    editorial: editorial,
    user: state.user,
    imageBaseUrl: imageBaseUrl,
    panelCoverSize: panelCoverSize,
    categoryMapping: categoryMapping,
    isPremiumUser: isUserPremium,
    userTags,
    contentCardsInObj,
  };
}

export default connect(mapStateToProps)(withRouter(AssetsPanel));

import { DefaultDocumentTitle } from 'constants/defaultConfig';
import getThemes, { themeNames } from '../utils/themes';

export default {
  systemConfig: {
    inProgress: false,
    isReady: false,
  },
  navigation: {
    inProgress: false,
  },
  content: {
    saag: {
      visibility: true,
    },
  },
  pages: [],
  editorials: [],

  // following sport metadata are arrays to capture multiple competitions sport metadata
  fixtures: [],
  results: [],
  tables: [],
  stats: [],
  commentary: [],
  highlights: [],
  lineup: [],
  squad: [],
  teamStats: [],
  teamSummary: [],
  teams: { inProgress: false, isSuccess: false, allTeams: [] },
  teamcolors: { inProgress: false, isSuccess: false, allTeamColors: [] },
  allteams: { inProgress: false, isSuccess: false, allteams: [] },
  allresults: { inProgress: false, isSuccess: false, allresults: [] },
  allfixtures: { inProgress: false, isSuccess: false, allfixtures: [] },
  matchhighlights: { inProgress: false, isSuccess: false, matchhighlights: [] },
  comparison: [],
  teamPreview: [],
  liveScores: [],
  livematches: [],
  saagdata: [],
  saagStatus: { inProgress: false, isSuccess: false },
  user: {
    initObserver: false,
    inProgress: false,
    loginFailure: null,
    logoutFailure: null,
    loggedIn: false,
    lastCustomToken: null,
    orders: null,
    vimondTokenLastCheckedTime: null,
    userType: {
      type: 'premium',
    },
    analytics: {
      analyticUserId: '-1',
    },
    authData: {},
    forgotPasswordResult: null,
    resetPasswordResult: null,
    changePasswordResult: null,
    validateEmail: {
      inProgress: false,
      isSuccess: false,
      isEmailRegistered: false,
      errorData: {},
    },
    createUserByVoucher: {
      inProgress: false,
      isSuccess: false,
      updateVoucherSubmitButton: false,
      createUserData: {},
    },
    subscriptionVoucher: {
      inProgress: false,
      isSuccess: false,
      voucherData: {},
    },
    subscriptionUserCreate: {
      inProgress: false,
      isSuccess: false,
      subscriptionData: {},
    },
  },
  ticketManager: {
    createTicket: {
      inProgress: false,
      isSuccess: false,
    },
    fetchTickets: {
      inProgress: false,
      isSuccess: false,
      tickets: [],
    },
    fetchComments: {
      inProgress: false,
      isSuccess: false,
      comments: {},
    },
    createComment: {
      inProgress: false,
      isSuccess: false,
    },
  },
  asset: {
    data: {},
    play: {},
    epg: null,
    playHistory: null,
    loadPlayInProgress: false,
    readyData: false,
    readyPlay: false,
    readyPlayHistory: false,
    readyEpg: false,
  },
  bannerAsset: {},
  assetCategory: {
    data: {},
    play: {},
    epg: null,
    playHistory: null,
    loadPlayInProgress: false,
    readyData: false,
    readyPlay: false,
    readyPlayHistory: false,
    readyEpg: false,
    loading: false,
  },
  documentTitle: DefaultDocumentTitle,
  metaDescription: `The World's Best Football. Optus Sport is the exclusive home of Premier League, Women's Super League. Every Game Live & On Demand. Watch now for $24.99 per month.`,
  themeConfig: {
    currentTheme: getThemes()[themeNames.dayTheme],
    themeAlert: false,
  },
  contentCards: {
    isReady: false,
    inProgress: false,
    isSuccess: false,
    data: false,
  },
};

import React, { useEffect, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { NewsArticleDetailPath } from 'constants/routesList';

import {
  chartBeatArticleDataMap,
  chartBeatInit,
  chartBeatPageDataMap,
  chartBeatTrack,
  chartBeatTrackEnd,
  setChartBeatUserType,
} from '..';
import useChartBeatConfig from '../hooks/useChartBeatConfig';
import { DefaultDocumentTitle } from 'constants/defaultConfig';
import { initObserver, isLoggedIn, isPremiumUser } from 'selectors/user';
import { isNumber, isFinite } from 'lodash';
import { isSSRUserAgent } from 'utils';

const DefaultChartBeatConfig = {
  routesAllowed: [],
  enabled: false,
  uid: '',
};

function ChartBeatContainer() {
  const { pathname } = useLocation();
  const isArticlePage = useRouteMatch(NewsArticleDetailPath);
  const {
    routesAllowed,
    enabled: isChartBeatEnabled,
    uid: chartBeatUid,
    filterBrowser,
  } = useChartBeatConfig() || DefaultChartBeatConfig;

  const {
    assetData,
    documentTitle,
    userInit,
    isUserLoggedIn,
    isUserPremium,
  } = useSelector((state) => {
    return {
      assetData: state?.asset?.data,
      documentTitle: state?.documentTitle,
      userInit: initObserver(state),
      isUserLoggedIn: isLoggedIn(state),
      isUserPremium: isPremiumUser(state),
    };
  });

  // to check if the path is allowed in config
  const isRouteAllowed = useRouteMatch(routesAllowed);

  // flag to check if ChartBeat script is loaded
  const [isChartBeatReady, setIsChartBeatReady] = useState(false);
  const [isChartBeatTracking, setIsChartBeatTracking] = useState(false);

  const [userType, setUserType] = useState(false);

  // callback function to set chartBeatReady
  const onloadHandler = (isScriptReady) => {
    if (isScriptReady) setIsChartBeatReady(true);
  };

  const isArticleReady = (assetId) => pathname.includes(assetId);

  const isValidUid = (uid) => {
    if (isNumber(uid) && isFinite(uid)) {
      return true;
    }
    return false;
  };

  const initChartBeat = (data) => {
    // to prevent chartBeatInit being called multiple time by re-rendering
    !isSSRUserAgent({ skipMobileWebView: true }) &&
      isChartBeatEnabled &&
      !isChartBeatTracking &&
      !isChartBeatReady &&
      isValidUid(chartBeatUid) &&
      chartBeatInit({
        data,
        chartBeatUid,
        callback: onloadHandler,
        filterBrowser,
      });
  };

  useEffect(() => {
    setChartBeatUserType(userType);
  }, [userType]);

  useEffect(() => {
    if (userInit) {
      // anon = not logged in
      // lgdin = basic user
      // paid = premium
      setUserType(isUserLoggedIn ? (isUserPremium ? 'paid' : 'lgdin') : 'anon');
    }
  }, [userInit, isUserLoggedIn, isUserPremium]);

  // track for non article pages
  useEffect(() => {
    if (
      isChartBeatEnabled &&
      isRouteAllowed &&
      !isArticlePage &&
      documentTitle !== DefaultDocumentTitle
    ) {
      const data = chartBeatPageDataMap({ pathname, documentTitle });
      if (isChartBeatReady) {
        chartBeatTrack(data);
      } else {
        // load chartbeat script after tracking data is ready
        initChartBeat(data);
      }
      setIsChartBeatTracking(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentTitle]);

  // tracking for articles
  useEffect(() => {
    const { id: assetId } = assetData || {};
    if (
      isChartBeatEnabled &&
      isRouteAllowed &&
      isArticlePage &&
      isArticleReady(assetId)
    ) {
      const data = chartBeatArticleDataMap(assetData);
      if (isChartBeatReady) {
        chartBeatTrack(data);
      } else {
        // load chartbeat script after tracking data is ready
        initChartBeat(data);
      }
      setIsChartBeatTracking(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetData]);

  // end tracking if path is not allowed
  useEffect(() => {
    if (
      isChartBeatEnabled &&
      isChartBeatReady &&
      isChartBeatTracking &&
      !isRouteAllowed
    ) {
      chartBeatTrackEnd();
      setIsChartBeatTracking(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return <></>;
}

export default React.memo(ChartBeatContainer);

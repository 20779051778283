import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import queryString from 'query-string';

import * as navigationAction from '../actions/navigation';
import * as teamActions from '../actions/teams';
import * as saagActions from '../actions/saagData';
import * as contentCardsAction from '../actions/contentCards';

import defaultConfig from '../constants/defaultConfig';
import { dataDogRum } from '../analytic/dataDog';

import { routesList as getRoutesList } from '../constants/routesList';
import { matchPath } from 'react-router-dom';

import PortalRoutes from './features/PortalRoutes';
import { SystemConfigContextProvider } from './context/withSystemConfig';

import { SearchProvider } from '../components/search';
import getFlags from 'ducks/features';
import { getUserId, userStrictType, isLoggedIn } from 'selectors/user';
import { isUserAndConfigReady } from 'selectors/contentCards';
import { userTypes } from 'ducks/user';

import { setBrazeUserProfile, setupBraze } from '../utils/braze';

const routeList = getRoutesList();

function isSaagUiVisible() {
  // 15th-June-2024 - Ken
  // Allow saag to be fetched/refreshed even if user viewport < 768px due to edge case of
  // user viewing live asset on smaller screen -- if this occurs, the live asset
  // match details will not update due to saag/live match dependency
  return true;

  // return window.innerWidth >= 768;
}

function checkSaagEnabledRoute() {
  const location = get(window, 'location');
  const currentRoute = routeList.find(
    (route) => route.name !== 'HomePage' && matchPath(location.pathname, route),
  );

  return !currentRoute.hideSaagBar;
}

function checkSaagEnabled({ setDevice }) {
  return (
    defaultConfig.saagEnabled &&
    // We should not load the saag for ?setDevice=mobile
    setDevice !== 'mobile'
  );
}

const SaagTimer = {
  live: 60000,
  inOneHour: 3600000,
  inThreeHours: 10800000,
  inSixHours: 21600000,
  inOneDay: 86400000,
};

const MonitorIntervalTime = 60000;

class App extends Component {
  constructor(props, context) {
    super(props, context);

    const location = props.location || get(window, 'location');
    const search = get(location, 'search', '');
    const searchParams = queryString.parse(search);
    const setDevice = get(searchParams, 'setDevice', 'desktop');

    this.state = {
      systemConfig: this.props.systemConfig,
      navigation: {},
      setDevice,
      saagDataReady: false,
      lastPathname: false,
    };
  }

  setTimerMonitor() {
    let latestSaagTime = 0;
    let saagTimeout = 0;
    return {
      setLatestSaagTime: (saagTimer) => {
        latestSaagTime = Date.now();
        saagTimeout = saagTimer;
      },
      init: () => {
        this.monitorTimer && clearInterval(this.monitorTimer);
        this.monitorTimer = setInterval(() => {
          const currentTime = Date.now();
          if (latestSaagTime + saagTimeout < currentTime) {
            // If saagTimer still not reset from the latest saag data recieved, manually trigger loadSAAGData
            if (this.timer) clearTimeout(this.timer);
            this.loadSAAGData();
          }
        }, MonitorIntervalTime);
      },
    };
  }

  getClosestMatch(saagData) {
    const isValidArray = (arr) => {
      return Array.isArray(arr) && arr.length;
    };

    const returnClosestMatch = (matches) => {
      // return match reduced by asc sorting with trueStartTimeEpochSeconds
      return (
        isValidArray(matches) &&
        matches.reduce(
          (matchA, matchB) =>
            matchA?.trueStartTimeEpochSeconds <
            matchB?.trueStartTimeEpochSeconds
              ? matchA
              : matchB,
          false,
        )
      );
    };

    const returnMatchesNotPast = (matches) => {
      return (
        isValidArray(matches) &&
        matches.filter(
          (match) =>
            // filter only matches that are LIVE or Not started yet
            typeof match?.MatchStatus === 'string' &&
            (match?.MatchStatus.toLowerCase() === 'fixture' ||
              match?.MatchStatus.toLowerCase() === 'live' ||
                match?.MatchStatus.toLowerCase() === 'half-time'
            ),
        )
      );
    };

    const closestMatchesPerLeague =
      isValidArray(saagData) &&
      saagData
        .map((league) => {
          const matchesNotPast = returnMatchesNotPast(league.info);
          return returnClosestMatch(matchesNotPast);
        })
        .filter(Boolean); // filter out if it's empty

    return returnClosestMatch(closestMatchesPerLeague);
  }

  getSaagTimer(saagData) {
    const closestMatch = this.getClosestMatch(saagData);
    const closestMatchStart = closestMatch?.trueStartTimeEpochSeconds || 0;
    const matchStartInThisHour =
      (closestMatchStart * 1000 - Date.now()) / 3600000;
    let nextSaagTimer = SaagTimer.live;
    if (matchStartInThisHour > 24) {
      nextSaagTimer = SaagTimer.inOneDay;
    } else if (24 >= matchStartInThisHour && matchStartInThisHour > 6) {
      nextSaagTimer = SaagTimer.inSixHours;
    } else if (6 >= matchStartInThisHour && matchStartInThisHour > 3) {
      nextSaagTimer = SaagTimer.inThreeHours;
    } else if (3 >= matchStartInThisHour && matchStartInThisHour > 1) {
      nextSaagTimer = SaagTimer.inOneHour;
    }
    return nextSaagTimer;
  }

  componentWillMount() {
    if (this.props.systemConfig && this.state.systemConfig.navigation) {
      this.props.actions.loadNavigations(this.state.systemConfig.navigation);
    }

    this.loadSAAGData();
  }

  componentWillReceiveProps(nextProps) {
    const { saagDataReady } = this.state;
    const {
      inProgress: currentInProgress,
      isSuccess: currentSuccess,
    } = this.props.saagStatus;
    const {
      inProgress: nextInProgress,
      isSuccess: nextSuccess,
    } = nextProps.saagStatus;
    if (
      currentInProgress &&
      !nextInProgress &&
      !currentSuccess &&
      nextSuccess
    ) {
      // if saagData request is success
      this.setNextSaagDataTimeout(nextProps.saagData);
      if (!saagDataReady) this.setState({ saagDataReady: true });
    } else if (
      currentInProgress &&
      !nextInProgress &&
      !currentSuccess &&
      !nextSuccess
    ) {
      // if saagData request is failure, retry in 60secs
      this.setNextSaagDataTimeout(nextProps.saagData, SaagTimer.live);
    }

    if (
      this.props.systemConfig.navigation !== nextProps.systemConfig.navigation
    ) {
      // menu navigation is updated, load the new menu navigation
      this.props.actions.loadNavigation(nextProps.systemConfig.navigation);
    }

    if (nextProps.isSystemConfigReady) {
      const getFeatureFlags = getFlags({
        systemConfig: nextProps.systemConfig,
      });
      const dataDogRumEnabled = getFeatureFlags('FEATURE_DATADOG_RUM');
      const dataDogSessionSampleRate = getFeatureFlags(
        'FEATURE_DATADOG_RUM_SESSION_SAMPLE_RATE',
      );
      if (dataDogRumEnabled) {
        if (!dataDogRum.isMonitoringStarted) {
          const { userId, userType: type, userLoggedIn: loggedIn } = nextProps;
          dataDogRum.init(
            {
              type,
              userId,
              loggedIn,
            },
            dataDogSessionSampleRate,
          );
        }
        if (!this.props.userLoggedIn && nextProps.userLoggedIn) {
          const { userId, userType: type, userLoggedIn: loggedIn } = nextProps;
          dataDogRum.onUserLoggedIn({
            type,
            userId,
            loggedIn,
          });
        }

        if (!nextProps.userLoggedIn) {
          dataDogRum.onUserLoggedOut({
            userId: '-1',
            loggedIn: false,
            type: userTypes.basic,
          });
        }
      }

      // Content Cards
      const {
        isUserAndConfigReady: currentIsUserAndConfigReady,
        contentCardsActions,
        userId,
        userSettings: currentUserSettings,
        userLoggedIn: currentUserLoggedIn,
      } = this.props;
      const {
        isUserAndConfigReady: nextIsUserAndConfigReady,
        user: userState,
        userId: nextUserId,
        userSettings: nextUserSettings,
        userLoggedIn: nextUserLoggedIn,
      } = nextProps;
      const promotionalPlacementsConfig = getFeatureFlags(
        'PROMOTIONAL_PLACEMENTS_CONFIG',
      );

      if (!currentIsUserAndConfigReady && nextIsUserAndConfigReady) {
        const {
          enabled: isPromotionalPlacementEnabled,
          backendValidation: isPromotionalPlacementBackendValidation,
        } = promotionalPlacementsConfig;
        if (
          isPromotionalPlacementEnabled &&
          isPromotionalPlacementBackendValidation
        ) {
          contentCardsActions.getContentCards(userId);
        }

        this.braze(userId, nextUserLoggedIn, promotionalPlacementsConfig);
      }

      // user Login status updated
      if (currentUserLoggedIn !== nextUserLoggedIn) {
        this.braze(nextUserId, nextUserLoggedIn, promotionalPlacementsConfig);
      }

      // user setting is updated so the userType is updated
      if (!currentUserSettings?.isSuccess && nextUserSettings?.isSuccess) {
        setBrazeUserProfile({
          ...userState?.profile,
        });
      }
    }
  }

  braze(userId, userLoggedIn, promotionalPlacementsConfig) {
    const { enabled, braze: brazeConfig } = promotionalPlacementsConfig;
    enabled && setupBraze({ featureConfig: brazeConfig, userId, userLoggedIn });
  }

  componentDidUpdate(prevProps) {
    if (this.props.navigation && this.props.navigation.id) {
      if (this.props.navigation.id !== prevProps.navigation.id) {
        if (
          this.props.navigation.navigations &&
          this.props.navigation.navigations.length
        ) {
          const navigations = this.props.navigation.navigations.filter(
            (nav) => nav.opta !== undefined,
          );
          if (navigations && navigations.length) {
            let allCompetitionIds = new Set();
            navigations.map((nav) => {
              if (nav.opta || nav.optaItems) {
                const optaCompetition = nav.opta.competitionId;
                const optaItemsCompetitions =
                  nav.optaItems?.map((o) => o.competitionId) || [];
                allCompetitionIds = new Set([
                  ...allCompetitionIds,
                  optaCompetition,
                  ...optaItemsCompetitions,
                ]);
              }
              return null;
            });
            this.props.teamActions.getTeams(allCompetitionIds);
          }
        }
      }
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    if (this.monitorTimer) {
      clearInterval(this.monitorTimer);
    }
  }

  handlerPathnameChange = (currentPathname) => {
    const { saagDataReady, lastPathname } = this.state;
    if (!saagDataReady && lastPathname !== currentPathname) {
      // to listen to url path change
      this.setState({ lastPathname: currentPathname });

      if (lastPathname) {
        // to avoid default state of lastPathname
        this.loadSAAGData();
      }
    }
  };

  loadSAAGData() {
    const { setDevice } = this.state;
    const { saagActions, saagData, asset } = this.props;
    const isAssetSaagDisabled = defaultConfig.saagDisabledAssetTypes.find(
      (assetType) => assetType === asset?.data?.assetTypeName,
    );

    // check if SAAG is enabled in conditions [config, setDevice]
    if (!checkSaagEnabled({ setDevice: setDevice })) return false;

    if (
      isSaagUiVisible() && // check if SAAG UI is visible
      !isAssetSaagDisabled && // check if the SAAG is hidden for the video asset types [fitness, news]
      checkSaagEnabledRoute() // check if SAAG enabled in route
    ) {
      saagActions.getSAAGData();
    } else {
      this.setNextSaagDataTimeout(saagData, SaagTimer.live);
    }
  }

  setNextSaagDataTimeout(saagData, fixedTimer) {
    const saagTimer =
      fixedTimer || this.getSaagTimer(saagData) || SaagTimer.live;
    if (this.timer) {
      clearTimeout(this.timer);
    }
    if (!this.timerMonitor) {
      this.timerMonitor = this.setTimerMonitor();
      this.timerMonitor.setLatestSaagTime(saagTimer);
      this.timerMonitor.init();
    }
    this.timerMonitor && this.timerMonitor.setLatestSaagTime(saagTimer);
    this.timer = setTimeout(() => this.loadSAAGData(), saagTimer);
  }

  render() {
    return (
      <SystemConfigContextProvider>
        <SearchProvider>
          <PortalRoutes
            setDevice={this.state.setDevice}
            onPathnameChange={this.handlerPathnameChange}
          />
        </SearchProvider>
      </SystemConfigContextProvider>
    );
  }
}

App.propTypes = {
  systemConfig: PropTypes.object.isRequired,
  navigation: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    asset: state.asset,
    systemConfig: state.systemConfig,
    isSystemConfigReady: state?.systemConfig?.isReady,
    navigation: state.navigation,
    saagData: state.saagdata,
    saagStatus: state.saagStatus,
    userLoggedIn: isLoggedIn(state),
    userType: userStrictType(state),
    userId: getUserId(state.user),
    isUserAndConfigReady: isUserAndConfigReady(state),
    user: state.user,
    userSettings: state.user?.settings,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(navigationAction, dispatch),
    teamActions: bindActionCreators(teamActions, dispatch),
    saagActions: bindActionCreators(saagActions, dispatch),
    contentCardsActions: bindActionCreators(contentCardsAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);

import { appDispatch } from 'index';
import {
  ANALYTIC_CONFIG,
  ANALYTIC_VENDOR_CONFIG,
  ANALYTIC_DEFAULT_VENDOR,
  ANALYTIC_DEFAULT_ACTION,
} from '../analytic/config';
import { identify, track, reset, page } from '../analytic/segment';
import { brazeEvent as event } from 'utils/braze';
import getFlags from './features';

const ANALYTICS_START_GENERIC = 'ANALYTICS_START_GENERIC';

const validateEventList = (array, limit = 50) => {
  // Remove first item in array and return the rest or return input
  if (array.length >= limit) {
    return array.slice(1);
  }
  return array;
};

// Collect key value pairs and return an object with a structure like { key:key }
const getObjectKeys = (entry = {}) =>
  Object.entries(entry).reduce((acc, pair) => {
    const [key] = pair;
    return { ...acc, [key]: key };
  }, {});

const mapAction = ({ actionType }) =>
  ({
    identify,
    track,
    page,
    reset,
    event,
  }[actionType || 'track']);

// Main and final point of process - determines which analytic events to make use of
const actionMapper = ({
  eventName,
  config = {},
  vendor,
  type,
  action,
  ...props
}) => {
  const appliedAction = mapAction({ actionType: action });
  if (appliedAction) {
    appliedAction({ eventName, type, ...props });
  }
};

// Lookup Vendor config - read through config and pull off vendor keys and return found matched vendor
const getVendorKey = ({ config = {}, vendor }) => {
  const getVendorList = getObjectKeys(config);
  return {
    ...getVendorList,
  }[vendor || null];
};

// Check the config to get the action validate it then return the mapper method with found action
const dispatchAction = ({
  eventName,
  config = {},
  vendor,
  action,
  type,
  ...props
}) => {
  const getActionList = Object.keys(config[vendor]?.actions);
  // Validate the action is found on the vendor
  if (!getActionList.includes(action)) {
    return;
  }
  return actionMapper({ config, vendor, type, action, eventName, ...props });
};

// Get analytic vendor (this will happen after array map) - this function is called for each iteration
const getByPair = ({
  eventName,
  config = {},
  pair = '',
  eventProps,
  ...props
}) => {
  if (!pair || typeof pair === 'object') {
    return;
  }

  const [
    vendor = ANALYTIC_DEFAULT_VENDOR,
    action = ANALYTIC_DEFAULT_ACTION,
    foundType,
    excludeCommonProps,
  ] = pair.split(':');

  // Determine default type or level passed via props
  const actionType = props.level || foundType;

  const foundVendorKey = getVendorKey({
    config: config,
    vendor: vendor,
  });

  let filteredProps = vendor === 'braze' ? { eventProps } : { ...eventProps };

  if (!excludeCommonProps || excludeCommonProps !== 'excludeCommonProps') {
    filteredProps = { ...filteredProps, ...props };
  }

  if (!foundVendorKey || !foundVendorKey.length) {
    return;
  }

  return dispatchAction({
    eventName: eventName,
    config: config,
    action: action,
    type: actionType,
    vendor: foundVendorKey,
    ...filteredProps,
  });
};

function getFeatureAnalytics(appState) {
  const getFeature = getFlags({ systemConfig: appState?.systemConfig });
  return getFeature('ANALYTICS');
}

// Initialiser
const fireAnalytic = ({
  config = {},
  vendorConfig = {},
  eventName,
  userId: userIdDirectProps,
  ...props
}) => {
  return (dispatch, getState) => {
    // Validate the event name pulled off from the latest item
    const eventList = config[eventName] || null;

    const featureAnalytics = getFeatureAnalytics(getState());
    const disabledEventsConfig = featureAnalytics?.disabledEvents || [];

    const filteredEventList = eventList
      .map((pair) => {
        const [eventVendor] = pair.split(':') || [];
        const eventInDisableFormat = `${eventName}::${eventVendor}`;
        return disabledEventsConfig.some(
          (disabledEvent) => disabledEvent === eventInDisableFormat,
        )
          ? false
          : pair;
      })
      .filter(Boolean);

    // stop analytic if
    // event not found ||
    // event disabled for example ['Competition'] ||
    // event disabled for a specific vendor for example ['Competition::braze']
    if (
      !eventList ||
      disabledEventsConfig.includes(eventName) ||
      filteredEventList.length === 0
    ) {
      return;
    }

    // Destructure and resolve state and props
    const {
      user: {
        userType: {
          updated: userTypeStatus = false,
          type: userType = undefined,
        } = {},
        userTags = undefined,
        analytics: { analyticUserId: userIdStoreProps } = {},
      } = {},
      subscription: { subscriptions = [] },
    } = getState() || {};

    const userProps = {};
    userProps.userId = userIdDirectProps || userIdStoreProps || '-1';
    userProps.userType = userType;
    userProps.userTypeStatus = userTypeStatus;
    userProps.userTags = userTags;
    userProps.userOfferIds = subscriptions
      ? subscriptions.map((s) => s.offerId)
      : [];

    return filteredEventList.map((item) =>
      getByPair({
        eventName,
        config: vendorConfig,
        pair: item,
        // Additional custom props
        ...userProps,
        eventProps: props,
      }),
    );
  };
};

// ACTIONS -- LEVEL PASSED IN VIA PROPS
const analyticAction = ({ eventName, ...props }) => {
  return (dispatch) => {
    // Keep this action to track events on the state
    dispatch({
      type: ANALYTICS_START_GENERIC,
      payload: eventName,
    });

    dispatch(
      fireAnalytic({
        eventName: eventName,
        config: ANALYTIC_CONFIG,
        vendorConfig: ANALYTIC_VENDOR_CONFIG,
        ...props,
      }),
    );
  };
};

// INITIAL STATE
const initialState = {
  items: [],
};

const analyticReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case ANALYTICS_START_GENERIC: {
      return {
        items: [...validateEventList(state.items, 2), payload],
      };
    }
    default:
      return state;
  }
};

function dispatchAnalytic(event) {
  const { name, option } = event || {};
  appDispatch(
    analyticAction({
      eventName: name,
      ...option,
    }),
  );
}

export { analyticAction, dispatchAnalytic };

export default analyticReducer;

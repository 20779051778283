import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import VendorButton from './VendorButton';
import { getDevice } from '../../../utils/userAgent';
import { initializeGoogleAnalytics, triggerGoogleAnalyticsEvent } from 'utils';
import { find } from 'lodash';

class FooterWidget extends Component {
  componentDidMount() {
    initializeGoogleAnalytics();
  }

  renderWidgetContent(type) {
    const footerLinkClass =
      'font-MarkProBold text-sm sm:text-base no-underline cursor-pointer text-teal os-transition-btn hover:text-teal-dark active:text-teal-light leading-24px';
    if (type === 'appstore-links') {
      const userDevice = getDevice();
      const deviceType = get(userDevice, 'type', 'PC');

      const downloadAppUrl =
        deviceType === 'PC'
          ? 'https://itunes.apple.com/au/app/optus-sport/id1113368382?mt=8'
          : 'https://c5pq6.app.goo.gl/?link=https://sport.optus.com.au&apn=au.com.optus.sport&isi=1113368382&ibi=au.com.optus.epl&ius=optussport';
      const downloadPlayStoreUrl =
        deviceType === 'PC'
          ? 'http://play.google.com/store/apps/details?id=au.com.optus.sport'
          : 'https://c5pq6.app.goo.gl/?link=https://sport.optus.com.au&apn=au.com.optus.sport&isi=1113368382&ibi=au.com.optus.epl&ius=optussport';

      return (
        <div className='widget-content xl:ml-80'>
          <div className='flex flex-col sm:flex-row xl:flex-col flex-no-wrap items-center items-stretch justify-center sm:justify-start mb-24 xl:mb-0'>
            <VendorButton
              link={downloadAppUrl}
              className='mr-0 sm:mr-8 max-w-full sm:min-w-253 xl:mr-0 rounded-full'
              type='apple'
            />

            <VendorButton
              link={downloadPlayStoreUrl}
              className='l-3 max-w-full sm:min-w-253 rounded-full'
            />
          </div>
        </div>
      );
    } else if (type === 'os-links') {
      const { history } = this.props;
      return (
        <div className='widget-content'>
          <ul className='list-reset m-0 p-0 mb-24 sm:mb-0'>
            <li className='mb-8'>
              <a
                href='/news'
                className={`${footerLinkClass} leading-1.14 xl:leading-normal`}
              >
                News &amp; Articles
              </a>
            </li>

            <li className='mb-8'>
              <a
                href='/epl'
                className={`${footerLinkClass} leading-1.14 xl:leading-normal`}
              >
                Premier League
              </a>
            </li>

            <li className='mb-8'>
              <a
                href='/j-league'
                className={`${footerLinkClass} leading-1.14 xl:leading-normal`}
              >
                J.League
              </a>
            </li>

            <li className='mb-8'>
              <a
                href='/womens'
                className={`${footerLinkClass} leading-1.14 xl:leading-normal`}
              >
                Women's Super League
              </a>
            </li>

            <li className='mb-8'>
              <a
                href='/nwsl'
                className={`${footerLinkClass} leading-1.14 xl:leading-normal`}
              >
                National Women's Soccer League
              </a>
            </li>

            <li className='mb-8'>
              <a
                href='/k-league'
                className={`${footerLinkClass} leading-1.14 xl:leading-normal`}
              >
                K League
              </a>
            </li>

            <li className='mb-8'>
              <a
                href='/dfb-pokal'
                className={`${footerLinkClass} leading-1.14 xl:leading-normal`}
              >
                DFB Pokal
              </a>
            </li>

            <li className='mb-8'>
              <a
                href='/internationals'
                className={`${footerLinkClass} leading-1.14 xl:leading-normal`}
              >
                Internationals
              </a>
            </li>

            <li className='mb-8'>
              <a
                href='/uefa-euro-2024'
                className={`${footerLinkClass} leading-1.14 xl:leading-normal`}
              >
                UEFA EURO 2024
              </a>
            </li>

            <li className='mb-8'>
              <a
                href='/copa-america-2024'
                className={`${footerLinkClass} leading-1.14 xl:leading-normal`}
              >
                Copa America USA 2024™
              </a>
            </li>

            <li className='mb-8 w-full inline-block'>
              <a
                href='/fitness'
                className={`${footerLinkClass} leading-1.14 xl:leading-normal`}
                onClick={(e) => {
                  e.preventDefault();
                  triggerGoogleAnalyticsEvent(
                    'Footer',
                    'click',
                    'Fitness Button',
                  );
                  history.push('/fitness');
                }}
              >
                Fitness
              </a>
            </li>

            <li className='mb-8 w-full inline-block'>
              <a
                href='/fitnessonoptussport'
                className={`${footerLinkClass} leading-1.14 xl:leading-normal`}
                onClick={(e) => {
                  e.preventDefault();
                  triggerGoogleAnalyticsEvent(
                    'Footer',
                    'click',
                    'Learn more about Fitness',
                  );
                  history.push('/fitnessonoptussport');
                }}
              >
                Learn more about Fitness
              </a>
            </li>
          </ul>
        </div>
      );
    } else if (type === 'help-links') {
      const { links = [] } = find(this.props.footerLinks, {
        widgettype: type,
      });
      return (
        <div className='widget-content'>
          <ul className='list-reset m-0 p-0 flex flex-col mb-24 sm:mb-0'>
            {links.map(({ text, url }, index) => {
              return (
                <li
                  className='mb-8 w-full  inline-block'
                  key={`help_link_${index}`}
                >
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href={url}
                    className={`${footerLinkClass} leading-1.14 xl:leading-normal`}
                  >
                    {text}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      );
    } else if (type === 'advertise-links') {
      return (
        <div className='widget-content'>
          <ul className='list-reset m-0 p-0 mb-24 sm:mb-0'>
            <li className='mb-8'>
              <a
                href='/advertise'
                className={`${footerLinkClass} leading-1.14 xl:leading-normal`}
              >
                Advertise on Optus Sport
              </a>
            </li>
            <li>
              <a
                href='/venues'
                className={`${footerLinkClass} leading-1.14 xl:leading-normal`}
              >
                Optus Sport in Your Venue
              </a>
            </li>
          </ul>
        </div>
      );
    }
    return null;
  }

  // renderWidgetFooter() {
  //   return (
  //     <footer className='widget-footer flex justify-center xl:mb-24 xl:justify-start xl:ml-200 xl:pl-20'>
  //       <a
  //         href='https://www.optus.com.au/for-you/entertainment/sport/optus-sport'
  //         target='_blank'
  //         rel='noopener noreferrer'
  //         className='text-teal font-MarkProHeavy text-sm no-underline os-transition-btn hover:text-teal-dark active:text-teal-light'
  //       >
  //         Find Out More
  //       </a>
  //     </footer>
  //   );
  // }

  render() {
    return (
      <aside className='footer-widget'>
        {this.props.title && (
          <header className='widget-header mb-8'>
            <h2
              className={`widget-title font-MarkProHeavy text-xl md:text-xl text-white ${
                this.props.widgettype === 'appstore-links'
                  ? 'text-center md:text-left xl:ml-80'
                  : 'text-left'
              }`}
            >
              {this.props.title}
            </h2>
          </header>
        )}

        {this.renderWidgetContent(this.props.widgettype)}

        {/* {this.props.widgettype === 'appstore-links'
          ? this.renderWidgetFooter()
          : null} */}
      </aside>
    );
  }
}

FooterWidget.propTypes = {
  /** Title for the widget.**/
  title: PropTypes.string.isRequired,
  /** Widget type.**/
  widgettype: PropTypes.oneOf([
    'appstore-links',
    'os-links',
    'help-links',
    'advertise-links',
  ]).isRequired,
  footerLinks: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

FooterWidget.defaultProps = {
  title: 'Footer Widget Title',
  widgettype: 'appstore-links',
  footerLinks: [],
};

export default withRouter(FooterWidget);

export const getTeamTable = (tableSummary, teamID) => {
  let teamTables = [];
  if (tableSummary.length) {
    tableSummary.map((items) => {
      if (items.rows && items.rows.length) {
        items.rows.map((team) => {
          if (team.TeamID === teamID) {
            teamTables = [...teamTables, items];
          }
          return null;
        });
      }
      return null;
    });
  }
  return teamTables;
};

export const getTeamFixtures = (fixtures, teamID) => {
  let teamFixtures = [];
  if (fixtures.length) {
    fixtures.map((items) => {
      if (items.rows && items.rows.length) {
        if (
          items.rows.findIndex(
            (fixture) =>
              fixture.HomeTeamID === teamID || fixture.AwayTeamID === teamID,
          ) > -1
        ) {
          teamFixtures = [...teamFixtures, items];
        }
        return null;
      }
      return null;
    });
  }
  return teamFixtures;
};

export const getTeamPosition = (data, teamID) => {
  return data.find((team) => team.TeamID === teamID);
};

export const getTeamsForTable = (data, teamPosition) => {
  let teamData = [];
  data.map((item) => {
    if (teamPosition === 1) {
      if (Number(item.Position) < 4) {
        teamData = [...teamData, item];
      }
    } else if (teamPosition === 20) {
      if (Number(item.Position) >= 18 && Number(item.Position) <= 20) {
        teamData = [...teamData, item];
      }
    } else {
      if (
        Number(item.Position) >= teamPosition - 1 &&
        Number(item.Position) <= teamPosition + 1
      ) {
        teamData = [...teamData, item];
      }
    }
    return null;
  });
  return teamData;
};

export const getFixtures = (fixture, competitionId) => {
  if (fixture && fixture.length) {
    return fixture.filter((fixture) => fixture.competitionId === competitionId);
  }
};

export const getResults = (results, competitionId) => {
  const resultData = results.filter(
    (result) => result.competitionId === competitionId,
  );
  if (resultData.length) {
    return resultData[0].rows;
  }
  return [];
};

export const getTableCaption = (competitionId) => {
  switch (competitionId) {
    case 8:
      return {
        link: '/epl/table',
        caption: 'Premier League',
      };
    case 5:
      return {
        link: '/uefa-champions-league/table',
        caption: 'UEFA Champions League',
      };
    case 6:
      return {
        link: '/uefa-europa-league/table',
        caption: 'UEFA Europa League',
      };
    case 941:
      return {
        link: '/internationals/table',
        caption: 'UEFA Nations League',
      };
    case 408:
      return {
        link: '/womens-world-cup-2023/table',
        caption: "FIFA Women's World Cup 2023™",
      };
    case 235:
      return {
        link: '/internationals/table',
        caption: 'EURO 2024 Qualifiers',
      };
    case 550:
      return {
        link: '/womens/table',
        caption: "FA Women's Super League",
      };
    case 335:
      return {
        link: '/fifa-club-world-cup/table',
        caption: 'FIFA Club World Cup™',
      };
    case 20:
      return {
        link: '/j-league/table',
        caption: 'J.League',
      };
    case 3:
      return {
        link: '/uefa-euro-2024/table',
        caption: 'UEFA EURO 2024',
      };
    case 832:
      return {
        link: '/nwsl/table',
        caption: "National Women's Soccer League",
      };
    case 205:
      return {
        link: '/k-league/table',
        caption: 'K League',
      };
    case 128:
      return {
        link: '/copa-america-2024/table',
        caption: 'Copa America USA 2024™',
      };
    case 231:
      return {
        link: '/dfb-pokal/table',
        caption: 'DFB Pokal',
      };
    default:
      return {
        link: '#',
        caption: '',
      };
  }
};

export const getLeagueData = (competitonID) => {
  switch (competitonID) {
    case 8:
      return {
        value: competitonID,
        label: 'English Premier League',
      };
    case 5:
      return {
        value: competitonID,
        label: 'UEFA Champions League',
      };
    case 6:
      return {
        value: competitonID,
        label: 'UEFA Europa League',
      };
    case 941:
      return {
        value: competitonID,
        label: 'UEFA Nations League',
      };
    case 408:
      return {
        value: competitonID,
        label: "FIFA Women's World Cup 2023™",
      };
    case 235:
      return {
        value: competitonID,
        label: 'EURO 2024 Qualifiers',
      };
    case 335:
      return {
        value: competitonID,
        label: 'FIFA Club World Cup™',
      };
    case 3:
      return {
        value: competitonID,
        label: 'UEFA EURO 2020™',
      };
    case 832:
      return {
        value: competitonID,
        label: "National Women's Soccer League",
      };
    case 128:
      return {
        value: competitonID,
        label: 'Copa América',
      };
    case 231:
      return {
        value: competitonID,
        label: 'DFB Pokal',
      };
    default:
      return {
        value: competitonID,
        label: 'English Premier League',
      };
  }
};

export const getSaagCompetition = (competitionID) => {
  switch (competitionID) {
    default:
    case 8:
      return 'epl';
    case 5:
      return 'ucl';
    case 6:
      return 'uel';
    case 941:
      return 'unl';
    case 235:
      return 'ecq2020';
    case 88:
      return 'int';
    case 408:
      return 'wwc';
    case 232:
      return 'uclq';
    case 550:
      return 'wsl';
    case 335:
      return 'fcwc';
    case 20:
      return 'j-league';
    case 3:
      return 'euc';
    case 205:
      return 'k-league';
    case 128:
      return 'coa';
    case 336:
      return 'wqe';
    case 642:
      return 'wwq';
    case 1166:
      return 'cuf';
    case 231:
      return 'dfb';
    case 832:
      return 'nwsl';
    case 38:
      return 'cos';
  }
};

export const getTeamLeagues = (competitions) => {
  if (competitions && competitions.length) {
    const sortedCompetitions = [];
    competitions.map((competitionItem) => {
      let competitionName = competitionItem.code;
      if (competitionItem.code === 'English Barclays Premier League') {
        competitionName = 'Premier League';
      }

      if (competitionItem.code === 'UEFA Europa Cup') {
        competitionName = 'UEFA Europa League';
      }

      sortedCompetitions.push({
        value: competitionItem.optaId,
        label: competitionName,
      });
      return null;
    });
    return sortedCompetitions;
  }
  return null;
};

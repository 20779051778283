import React from 'react';
import PropTypes from 'prop-types';
import AssetsPanel from '../../../features/AssetsPanel/';

export const RailCarousel = ({
  carouselId,
  carouselIndex,
  title,
  classes,
  sectionPath,
  showViewAllLink,
  theme,
  target,
  fitnessLanding,
  viewAllUrl,
  page,
  type,
}) => (
  <AssetsPanel
    carouselId={carouselId}
    carouselIndex={carouselIndex}
    title={title}
    classes={`rail-carousel ${classes}`}
    sectionPath={sectionPath}
    newHome
    showViewAllLink={showViewAllLink}
    theme={theme}
    target={target}
    fitnessLanding={fitnessLanding}
    viewAllUrl={viewAllUrl}
    page={page}
    type={type}
  />
);

RailCarousel.propTypes = {
  carouselId: PropTypes.string,
  title: PropTypes.string,
  classes: PropTypes.string,
  sectionPath: PropTypes.string,
  showViewAllLink: PropTypes.bool,
  theme: PropTypes.string,
  target: PropTypes.string,
  fitnessLanding: PropTypes.bool,
  page: PropTypes.string,
};

RailCarousel.defaultProps = {
  carouselId: '',
  carouselIndex: null,
  title: '',
  classes: '',
  sectionPath: null,
  showViewAllLink: true,
  theme: 'dark',
  target: '_self',
  fitnessLanding: false,
  page: null,
};
